<template>
  <div class="container" id="content">
    <TableBox
        ref="tableBox"
        :exportBtn="exportBtn"
        :disposeData="disposeData"
        :rowBtns="rowBtns"
        :tableHeader="tableHeader"
        request="/inputPurchase/pageRepertory"

    ></TableBox>

    <UpdataModal
      ref="updata"
      :showFlag="showFlag"
      :messageBox="messageBox"
      :formTitle="formTitle"
      @close="closeModal"
      @success="refresh"
      :record="addForm"
    ></UpdataModal>
  </div>
</template>
<script>
import UpdataModal from "./modal/updata.vue";
import TableBox from "@/components/lxw/TableBox/index.vue";

export default {
  name: "lawsAReg",
  components: { UpdataModal, TableBox },
  data() {
    return {
      loading: false,
      ids: 0,
      addForm: {},
      exportBtn: false,
      disposeData: false,
      tableHeader: [
        {
          label: "产品名称",
          key: "inputName",
          type: "Input",
        },
        {
          label: "规格",
          key: "unit",
          type: "Input",
        },
        {
          label: "库存数量",
          key: "quantity",
          type: "InputNumber",
        },
       
      ],
      rowBtns: [],

      formTitle: "",
      messageBox: false,
      showFlag: false,
      token: "",
      filesList: [],
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));
  },
  methods: {

    refresh() {
      this.messageBox = false;
      this.$refs.tableBox.getList();
    },

    closeModal() {
      this.messageBox = false;
    },
  },
};
</script>

<style scoped>
</style>
